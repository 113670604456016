
.banner-bg{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  /* height: 1036px ; */
  background-color: var(--color-3);
  bottom: 0;
  z-index: -1;
}

.bg{
  position: relative;
}
.banner-inner{
  padding-top: 8rem;
}
.banner-content{
  height: 100vh;
  display: flex;
  align-items: center;
  padding-bottom: 10rem;
}
.banner-content h1{
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.45px;
  color: var(--color-5);
  margin-bottom: 20px;
}

.banner-content p{
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; 
  color: var(--color-5);
  margin-bottom: 20px;
}
.banner-content img{
  border-radius: 30px;
  border: 5px solid var(--color-1);

}
.banner-img{
  text-align: right;
  padding-right: 0;
  position: relative;
}
.banner-img::after{
  content: "";
  background-image: url(../images/dots1.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  right: -60px;
  bottom: -60px;
  z-index: -1;
  height: 100%;
  width: 170px;
  background-position: bottom;
  
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

}
@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }    
}



/* Slider */
#techSlider .item{
  opacity: 0.4;
  text-align: center;
}
#techSlider .item p{
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: 600;

}
.slick-slide img {
    display: unset !important;
}
#techSlider{
  margin: 5rem 0;
}


/* services section */
/* .services-section{
  margin: 5rem 0;
} */

.card-box{
  border-radius: 30px;
  background-color: var(--color-6);
  padding: 20px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;
  display: block;
}
.card-box figure{
  background-color: var(--color-1);
  border-radius: 20px;
  width: 65px;
  height: 65px;
  text-align: center;
  padding: 16px;
}
.card-box h4{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}
.card-box p{
  font-size: 13px;
  margin-bottom: 10px;
}
.card-box:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 12px;
  right: -47px;
  background: var(--color-3);
  height: 32px;
  width: 42px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.6s ease-out;
}
.card-box:hover:before {
  transform: scale(21);
}
.card-box:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.card-box:hover h4 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}



/* welcome-section */
.welcome-section{
  margin: 8rem 0 7rem;
}
.welcome-box figure{
  background-color: var(--color-1);
  border-radius: 20px;
  width: 65px;
  height: 65px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 0;
}
.welcome-box{
  margin-bottom: 30px;
}
.welcome-box h4{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.welcome-box p{
  font-size: 14px;
  margin-bottom: 0;
  max-width: 400px;
  width: 100%;
  line-height: normal;
}
.welcome-img{
  position: relative;

}
.welcome-img::before{
  content: "";
  background-image: url(../images/rec1.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  left: -180px;
  top: -60px;
  width: 100%;
  z-index: -1;
  height: 75%;
  background-position: top;
}
.welcome-img::after{
  content: "";
  background-image: url(../images/dots1.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  left: -30px;
  bottom: -30px;
  z-index: -1;
  height: 100%;
  width: 100px;
  background-position: bottom;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

}
@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }    
}

.welcome-img img{
  border-radius: 30px;
  border:5px solid var(--color-1);
  max-height: 500px;
  height: 100%;
  width: 90%;
  object-fit: cover;
}




/* industry-section css */

.industry-section{
  margin: 5rem 0;
  background-color: var(--color-6);
  padding: 5rem 0;
}
.white-box{
  border-radius: 30px;
  background-color: var(--color-5);
  padding: 30px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;
  display: block;
}
.white-box h4{
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: 600;
}
.white-box figure {
  background-color: var(--color-1);
  border-radius: 20px;
  width: 65px;
  height: 65px;
  text-align: center;
  padding: 16px;
  margin-bottom: 0;
}

.white-box p{
  height: 130px;
}

.white-box:before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 12px;
  right: -60px;
  background: var(--color-3);
  height: 32px;
  width: 60px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.6s ease-out;
}
.white-box:hover:before {
  transform: scale(21);
}

.white-box:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.white-box:hover h4 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}
.white-box:hover hr{
  background-color: var(--color-5);
}



/* approch section */
.approach-section{
  margin: 8rem 0 7rem;
}

.approach-box figure{
  background-color: var(--color-1);
  border-radius: 20px;
  width: 80px;
  height: 80px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-right: 20px;
  margin-bottom: 0;
}
.approach-box{
  margin-bottom: 30px;
}
.approach-box h4{
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 0;
}
.approach-box p{
  font-size: 14px;
  margin-bottom: 0;
  max-width: 400px;
  width: 100%;
  line-height: normal;
}
.Approach-img{
  position: relative;

}
.Approach-img img{
  border-radius: 30px;
  border: 5px solid var(--color-1);
}
.Approach-img::before{
  content: "";
  background-image: url(../images/dots1.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  right: 30px;
  top: -30px;
  z-index: -1;
  height: 100%;
  width: 100px;
  background-position: top;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

}
@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }    
}

.Approach-img::after{
  content: "";
  background-image: url(../images/rec1.png);
  background-repeat: no-repeat;
  /* display: block; */
  position: absolute;
  bottom: -80px;
  z-index: -1;
  left: 50%;
  height: 300px;
  width: auto;
  right: 0;
  
}

.brand-slider{
  margin: 5rem 0;
  padding: 4rem 0;
  background-color: var(--color-6);
}
#brandSlider{
  margin-top: 3rem;
}


#brandSlider .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  margin-left: auto;
  align-items: center;
  margin-right: auto;
}


.testimonial-section{
  margin: 5rem 0;
}
.testimonial-section figure{
  position: relative;
}
.testimonial-section figure::after{
  content: "";
  background-image: url(../images/rec1.png);
  background-repeat: no-repeat;
  height: 800px;
  width: 800px;
  position: absolute;
  bottom: -460px;
  z-index: -1;
  left: -45px;
}
.testimonial-section h3{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}
.testimonial-section .slick-dots {

  bottom: -34px;
  
  display: flex;
  justify-content: flex-start;
}
.testimonial-section .slick-dots li button:before{
  
  font-size: 12px;
  height: 0;
  width: 0;
}
.testimonial-section .slick-dots li{
  
  margin: 0;
}
.testimonial-section .slick-dots li.slick-active button:before{

  color: var(--color-1);
}

/* our advantages */
.our-advantages-section{
  margin: 5rem 0;
}
.our-advantages-section .box{
  margin-bottom: 20px;
  margin-top: 50px;
}
.our-advantages-section .box figure{
  width: 100px;
  height: 100px;
  background-color: var(--color-1);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin-right: 20px;
}
.our-advantages-section .box h2{
  font-size: 30px;
  font-weight: 700;
}

/* who we are css */

.we-section{
  margin: 5rem 0;
  background-color: var(--color-6);
  padding: 4rem 0;
}
.we-section .card{
  border-radius: 30px;
  background: var(--color-5);
  padding: 30px;
  border: 0;
  margin-top: 20px;
}
.we-section .card h4{
  font-size: 22px;
  font-weight: 700;
}

/* inner pages css  */
.inner-page-section{
  margin: 5rem 0;
}
.inner-page-section h2{
  font-size: 30px;
  font-weight: bold;
}
.inner-page-section .inner-page-content{
  padding: 4rem;
}
.inner-page-image-one, .inner-page-image-two{
  position: relative;
  text-align: center;
}
.inner-page-image-one::before {
  content: "";
  background-image: url(../images/rec3.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  right: -38px;
  top: 40px;
  width: 100%;
  z-index: -1;
  height: 100%;
  background-position: top;
}

.inner-page-image-two::before {
  content: "";
  background-image: url(../images/rec3.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  left: -40px;
  top: 35px;
  width: 100%;
  z-index: -1;
  height: 100%;
  background-position: top;
}
.inner-p{
  position: relative;
  top: -4px;
}


/* portfolio css */

.project-box{
  border-radius: 30px;
  border: 5px solid var(--color-1);
  position: relative;
   overflow: hidden; 
  cursor: pointer;
  display: block;
  margin: 20px;
}


.project-box h4{
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: 600;
}

.project-box p{
  height: 130px;
}

.project-box:before {
  content: "";
  position: absolute;
  /* z-index: -1; */
  bottom: 12px;
  right: -60px;
  background: #000000db;
  height: 45px;
  width: 65px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.6s ease-out;
  
}
.project-box:hover:before {
  transform: scale(21);
}

.project-box:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.project-box:hover h4 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}
.project-box{
  position: relative;
}
.project-box div{
  position: absolute;
  top: 0;
  opacity: 0;
  left: -35px;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
  max-width: 330px;
  width: auto;
  text-align: center;
}
.project-box:hover div{
  opacity: 1;
}
.yellow-b img{
  border-radius: 30px;
  border: 5px solid var(--color-1);
}

/* solutions css */
.products-section{
  padding: 5rem 0;
  background-color: var(--color-6);
}
.products-section .card{
  border-radius: 30px;
  border: 2px solid var(--color-4);
  text-align: center;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  

}
/* @media (min-width: 400px) {
  .products-section .card {
    gap: 20px;
  }
} */

/* contact css */
.contact-block{
  padding: 5rem 0;
}
.contact-block .form-control{
  margin-bottom: 16px;
  height: 45px;
  border-radius: 30px;
  border: 1px solid var(--color-1);
  color: var(--color-3);
  font-weight: 500;
}
.contact-block .form-label {
  margin-bottom: 0.5rem;
  font-size: 16px;
}
.contact-block p{
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 18px;
}
.contact-block .btn-dark{
  height: 45px;
    margin-top: 15px;
    border-radius: 30px;
    background: var(--color-1);
    border: 0;
    font-size: 16px;
    color: var(--color-3);
    font-weight: 600 !important;
}


/* teams section */
.team-section{
  padding: 5rem 0;
}
.team-section .team-box{
  border-radius: 30px;
  border: 3px solid var(--color-1);
  padding: 20px;
  margin-bottom: 30px;
  height:250px

}
.team-section .team-box h2{
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 4px;
}
.team-section .team-box h5{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
  color: var(--color-1);
}

.partner-products{
  padding: 5rem 0;
  background-color: var(--color-6);
}
.partner-products h2{
  font-size: 30px;
  font-weight: bold;
}

.partner-products .w-box {
  border-radius: 30px;
  background-color: var(--color-5);
  padding: 30px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;
  display: block;
 
}
.partner-products .w-box p{
  height: 260px;
  
}

@media screen and (max-width:1200px) {
  .banner-content h1 {
    font-size: 38px;
  }
  
}
@media screen and (max-width:991px) {
  .banner-img::after,
  .welcome-img::before,
  .welcome-img::after,
  .Approach-img::before,
  .Approach-img::after,
  .testimonial-section figure::after{
    display: none;
  }  

  .banner-content h1{
    text-align: center;
    color: var(--color-3);  
    margin-top: 3rem;
  }
  .banner-content p{
    font-size: 14px;
    color: var(--color-3);
    text-align: center;
    line-height: 18px;
  }
  .quote-btn{
    max-width: 140px;
    display: block;
    padding: 10px 20px !important;
    margin: 0 auto 2rem;
  }

  .sub-heading {
    font-size: 30px;
    text-align: center;
  }
  .card-box{
    margin-bottom: 20px;
  }
  /* .services-section{
    margin: 3rem 0;
  } */
  .sub-heading-p{
    max-width: unset;
    text-align: center;
  }
  .welcome-box .d-flex, .approach-box .d-flex{
    justify-content: center;
  }
  .welcome-section{
    margin: 3rem 0 1rem;
  }
  .industry-section {
    margin: 2rem 0px;
  }
  .white-box{
    margin-bottom: 20px;
  }
  .white-box p {
    height: auto;
  }
  .approach-section,.brand-slider{
    margin: 3rem 0;
  }
 
  .banner-content {
    height: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 5rem;
  }

#brandSlider{
  margin-top: 2rem;
}
.testimonial-section .col-lg-6{
  text-align: center;
}
.testimonial-section .slick-dots{
  justify-content: center;
}
.brand-slider, .tech-Slider{
  display: none;
}
.welcome-section p img{
  display: none;
}
.welcome-section p{
  text-align: center;
  justify-content: center;
}
.our-advantages-section .box h2{
  font-size: 22px;
}
.our-advantages-section .box {
  margin-bottom: 0px;
  margin-top: 24px;
}
.our-advantages-section .box figure {
  width: 90px;
  height: 90px;
}
.inner-page-section .inner-page-content {
  padding: 0rem;
}
.inner-page-section .inner-page-content h2 {
  font-size: 25px;
}
.contact-block p{
  font-size: 16px;
  text-align: center;
  line-height: normal;
}
}