@font-face {
  font-family: "Roboto";
  src: url("../font/Roboto-Black.woff2") format("woff2"),
    url("../font/Roboto-Black.woff") format("woff");
  font-weight: 900;
}
@font-face {
  font-family: "Roboto";
  src: url("../font/Roboto-Bold.woff2") format("woff2"),
    url("../font/Roboto-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("../font/Roboto-Light.woff2") format("woff2"),
    url("../font/Roboto-Light.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url("../font/Roboto-Regular.woff2") format("woff2"),
    url("../font/Roboto-Regular.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("../font/Roboto-Medium.woff2") format("woff2"),
    url("../font/Roboto-Medium.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Roboto";
  src: url("../font/Roboto-Thin.woff2") format("woff2"),
    url("../font/Roboto-Thin.woff") format("woff");
  font-weight: lighter;
}

:root {
  /* Colors */
  --color-1: #ffb600;
  --color-2: #25259f;
  --color-3: #000000;
  --color-4: #e07243;
  --color-5: #ffffff;
  --color-6: #fff8ee;
  --color-7: #333333;
  --color-8: #787878;
  --color-9: #d9d9d9;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
  font-family: "Roboto";
  padding: 0;
  margin: 0;
  font-weight: 500;
  color: var(--color-3);
  overflow-x: hidden;
  overflow-y: auto;
  vertical-align: middle;
}
a {
  text-decoration: none;
}
a,
a:hover {
  color: inherit;
}

.nav-link {
  color: var(--color-11);
  font-size: 14px;
  transition: none;
}
.nav-link:focus,
.nav-link:hover {
  color: var(--color-11);
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.fixed-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: var(--color-3);
}
header .dropdown-menu.show {
  min-width: 230px;
  width: 100%;
  left: 28px;
}
header .dropdown-item.active,
header .dropdown-item:active {
  color: var(--color-2);
  text-decoration: none;
  background-color: transparent;
  font-weight: 500;
}
header .dropdown-item,
header .dropdown-item {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-3);
  padding: 6px 20px;
}
header .dropdown-item:focus,
header .dropdown-item:hover {
  color: var(--color-2);
  background-color: transparent;
}

header .navbar-expand-lg .navbar-collapse {
  flex-basis: auto;
  flex-direction: column;
  align-items: flex-end;
}

.navbar .navbar-expand-lg {
  position: relative;
  top: -36px;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--color-3);
  text-decoration: none;
  background-color: var(--color-1);
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}
.dropdown-item {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  color: var(--color-3);
}
.close-icon {
  display: none;
}
.main-nav .navbar-collapse {
  position: relative;
  top: 0;
  z-index: 3;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 15px;
  padding-left: 10px;
  font-weight: 500;
}
.nav-link.order-btn {
  background-color: var(--color-11);
  color: var(--color-2);
  font-size: 16px;
  font-weight: 600 !important;
  padding: 12px 30px !important;
  margin-left: 30px;
}
.navbar-expand-lg .navbar-nav .nav-link.active {
  color: var(--color-4);
}
/* .navbar-expand-lg .navbar-nav .nav-link:hover {
  color: var(--color-3);
} */
.navbar-expand-lg .navbar-nav .nav-link.order-btn:hover {
  border-bottom: 4px solid var(--color-3);
  color: var(--color-2);
}
.home-header .navbar-brand img {
  height: 90px;
}

.quote-btn {
  background-color: var(--color-1);
  border-radius: 40px;
  padding: 12px 30px !important;
  font-weight: 600;
  transition: all 0.3s ease;
  color: var(--color-3) !important;
}
.quote-btn:hover,
.quote-btn:focus,
.quote-btn:active {
  background-color: var(--color-1);
  font-weight: 700;
  box-shadow: -1px 6px 10px 0 rgba(120, 60, 20, 0.2);
}

.bg-white {
  background-color: var(#fff);
  color: #000000 !important;

  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  -webkit-animation: fadeInDown 0.5s ease-in-out 0s 1 normal none running;
  animation: fadeInDown 0.5s ease-in-out 0s 1 normal none running;
}

.btn-black {
  background-color: var(--color-3);
  border-radius: 40px;
  padding: 12px 30px !important;
  font-weight: 600;
  color: var(--color-5);
  transition: all 0.3s ease;
}
.btn-black:hover,
.btn-black:focus,
.btn-black:active {
  background-color: var(--color-3);
  color: var(--color-5);
  box-shadow: -1px 6px 10px 0 rgba(120, 60, 20, 0.2);
}

.sub-heading {
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 12px;
}
.sub-heading span {
  text-transform: uppercase;
}
.yellow {
  color: var(--color-1);
}
.sub-heading-p {
  max-width: 500px;
  width: 100%;
}

/* subscribe css */
.subscribe-section {
  /* margin: 5rem 0 0; */
  padding: 3rem 0;
  background-color: var(--color-1);
}
.subscribe-section h2 {
  font-size: 35px;
  font-weight: 700;
}
.subscribe-section p {
  font-size: 16px;
  font-weight: 500;
}
.subscribe-section h2 span {
  color: var(--color-2);
}

/* footer css  */
footer {
  background-color: var(--color-3);
}
footer .container {
  padding: 5rem 0 0;
}
footer p {
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--color-5);
}
footer a:hover {
  color: var(--color-1);
}
footer h4 {
  font-size: 18px;
  color: var(--color-5);
  margin-bottom: 12px;
}

.footer-bottom {
  padding-bottom: 1rem;
}

.footer-dec {
  max-width: 450px;
  width: 100%;
  font-weight: 500;
  padding-top: 1rem;
}
footer hr {
  background-color: var(--color-8);
}

/* internal pages css */
.banner-section {
  background-image: url(../images/internal-header.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
  width: 100%;
  margin-top: 0;
}
.banner-section .page-header {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.banner-section .navbar-expand-lg .navbar-nav .nav-link {
  color: var(--color-5);
}
.banner-section .navbar-expand-lg .navbar-nav .nav-link.active {
  color: var(--color-1);
}
/* .banner-section .navbar.bg-white .nav-link {
  color: var(--color-3);
} */
.banner-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-section h2 {
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.45px;
  color: var(--color-5);
}

@media screen and (max-width: 1200px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 10px;
    padding-left: 4px;
    font-weight: 500;
    font-size: 13px;
  }
}
@media screen and (max-width: 991px) {
  .navbar {
    background-color: var(--color-3) !important;
  }
  .bg-white {
    background-color: transparent;
  }
  .navbar-brand img {
    height: 70px;
  }
  .navbar-collapse.justify-content-end.collapse {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    background: var(--color-3);
    padding: 20px 2rem;
  }
  .navbar-collapse.justify-content-end.collapsing {
    background: var(--color-3);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    padding: 20px 2rem;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    color: var(--color-6);
  }
  .close-icon {
    /* display: block; */
    text-align: right;
  }
  .nav-link.quote-btn {
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subscribe-section h2 {
    font-size: 28px;
  }
  .subscribe-section p {
    font-size: 14px;
  }
  .btn-black {
    font-size: 14px;
    padding: 12px 30px !important;
    font-weight: 500;
  }
  footer p {
    font-size: 14px;
  }
  footer h4 {
    font-size: 16px;
  }
  footer .container {
    padding: 2rem 0 0;
  }
  .footer-dec {
    max-width: unset;
    width: 100%;
    font-weight: 500;
    padding-top: 1rem;
    padding-bottom: 20px;
  }
  .footer-bottom img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 767px) {
  .nav-link.order-btn {
    margin-left: 0;
    text-align: center;
  }
  .navbar-toggler:focus {
    outline: none;
    box-shadow: unset;
  }

  .search-container input {
    width: unset;
  }
  .search-container button {
    padding: 16px 20px;
    font-weight: 600;
    font-size: 14px;
  }
  footer .container {
    padding: 2rem 10px 0;
  }
  footer h4 {
    margin-bottom: 12px;
    margin-top: 30px;
  }
  .footer-bottom {
    text-align: center;
  }
  .footer-dec {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 575px) {
  .subscribe-section h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
  }
  .subscribe-section p {
    font-size: 16px;
  }
}
